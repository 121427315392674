import { Address } from "../../@type/Orders/Address";
import {
  CustomsDeclarationsType,
  reqServiceOptionType,
  ServiceOption,
} from "../../@type/Orders/Order";
import { ShippingItem } from "../../@type/ShippingItem";

// the library has bug when taking parameter to transform in array item
var objectMapper = require("object-mapper");

export const createShipmentReqMapper = (data: any, isLTL: boolean) => {
  let request;
  if (!isLTL) {
    if (data.isEEIChecked && data.selectedRate.carrierName === "UPS") {
      request = objectMapper(data, map);
    } else if (!data.isEEIChecked && data.selectedRate.carrierName === "UPS") {
      request = objectMapper(data, mapWithoutEEIChecked);
    } else {
      request = objectMapper(data, map);
    }
    // if (!request.ToAddress?.companyName && request.carrierServiceType.includes("UPS")) {
    //     request.rateRequest.ToAddress.companyName = request.rateRequest.ToAddress.name
    // }
  } else {
    request = objectMapper(data, mapLTL);
  }
  return request;
};

const map = {
  "shipFrom.name": "rateRequest.FromAddress.name",
  "shipFrom.companyName": "rateRequest.FromAddress.companyName",
  "shipFrom.streetAddress": "rateRequest.FromAddress.streetAddress",
  "shipFrom.streetAddress2": "rateRequest.FromAddress.streetAddress2",
  "shipFrom.city": "rateRequest.FromAddress.city",
  "shipFrom.country": "rateRequest.FromAddress.country",
  "shipFrom.countryCode": "rateRequest.FromAddress.countryCode",
  "shipFrom.state": "rateRequest.FromAddress.state",
  "shipFrom.stateCode": "rateRequest.FromAddress.stateCode",
  "shipFrom.postalCode": "rateRequest.FromAddress.postalCode",
  "shipFrom.isResidence": "rateRequest.FromAddress.isResidence",
  "shipFrom.email": {
    key: "rateRequest.FromAddress.email",
    transform: function (value: string) {
      if (value === "") {
        return null;
      } else {
        return value;
      }
    },
  },
  "shipFrom.phone": "rateRequest.FromAddress.phone",
  // "shipFromAddressId":"rateRequest.FromAddress.shipFromAddressId",
  "shipTo.name": "rateRequest.ToAddress.name",
  "shipTo.companyName": "rateRequest.ToAddress.companyName",
  "shipTo.streetAddress": "rateRequest.ToAddress.streetAddress",
  "shipTo.streetAddress2": "rateRequest.ToAddress.streetAddress2",
  "shipTo.city": "rateRequest.ToAddress.city",
  "shipTo.country": "rateRequest.ToAddress.country",
  "shipTo.countryCode": "rateRequest.ToAddress.countryCode",
  "shipTo.state": "rateRequest.ToAddress.state",
  "shipTo.stateCode": "rateRequest.ToAddress.stateCode",
  "shipTo.postalCode": "rateRequest.ToAddress.postalCode",
  "shipTo.isResidence": "rateRequest.ToAddress.isResidence",
  "shipTo.email": {
    key: "rateRequest.ToAddress.email",
    transform: function (value: string) {
      if (value === "") {
        return null;
      } else {
        return value;
      }
    },
  },
  "shipTo.phone": "rateRequest.ToAddress.phone",
  shippingItems: {
    key: "rateRequest.lineItems",
    transform: function (value: ShippingItem[]) {
      const lineItems = [];

      value.forEach((item) => {
        for (let i = 0; i < item.quantity; i++) {
          lineItems.push({
            length: item.length ? parseFloat(item.length) : 0,
            height: item.height ? parseFloat(item.height) : 0,
            width: item.width ? parseFloat(item.width) : 0,
            weight:
              item.weight && item.weight !== "" ? parseFloat(item.weight) : 0,
            declaredValue: {
              currency: "CAD",
              amount: item.insurance ? parseFloat(item.insurance) : 0,
            },
            PackagingType: item.package.code,
            InsuranceType: item.InsuranceType ? item.InsuranceType.code : null,
          });
        }
      });
      return lineItems;
    },
  },
  unitOfMeasurement: "rateRequest.unitOfMeasurement",
  "selectedRate.account.id": "shippingCarrierAccountId",
  "selectedRate.price": "rateRequest.shipmentCharges",
  "selectedRate.account.accountType": "accountType",
  "selectedRate.service.serviceCode": {
    key: "carrierServiceType",
    transform: function (value: any) {
      return value.replaceAll(".", "_");
    },
  },
  shipDate: "shipDate",
  labelOptions: {
    key: "LabelOptions",
    transform: (value) => {
      let emailTemp = value.Email === undefined ? undefined : value.Email;
      let labelOptionsTemp = {
        Email: emailTemp,
        ShipmentLabelSize: value.labelSize,
      };
      return labelOptionsTemp;
    },
  },
  // "labelSize": "ShipmentLabelSize",
  serviceOptions: {
    key: "rateRequest.serviceOptions",
    transform: function (value: {
      serviceOption: ServiceOption;
      prerequisiteOptions?: ServiceOption[];
      ifNonDeliveryOption?: ServiceOption;
      saturdayDelivery?: ServiceOption;
      LTLOptions?: ServiceOption[];
    }) {
      const serviceOptions: reqServiceOptionType[] = [];
      if (value.serviceOption.optionCode)
        serviceOptions.push({
          code: value.serviceOption.optionCode,
          qualifier: value.serviceOption.qualifier,
          postOfficeAddress: value.serviceOption.postOfficeAddress?.countryCode
            ? value.serviceOption.postOfficeAddress
            : { ...value.serviceOption.postOfficeAddress, countryCode: "CA" },
        });
      // if (value.insuranceOption.amount) serviceOptions.push({
      //     code: value.insuranceOption.optionCode,
      //     amount: parseFloat(value.insuranceOption.amount),
      // });
      // if (value.CODOption.amount) serviceOptions.push({
      //     code: value.CODOption.optionCode,
      //     amount: parseFloat(value.CODOption.amount),
      //     qualifier: value.CODOption.qualifier
      // });
      if (value.prerequisiteOptions)
        serviceOptions.push(
          ...value.prerequisiteOptions.map((option) => {
            return {
              code: option.optionCode,
            };
          })
        );
      if (value.ifNonDeliveryOption?.optionCode)
        serviceOptions.push({
          code: value.ifNonDeliveryOption.optionCode,
        });
      if (value.saturdayDelivery?.optionCode)
        serviceOptions.push({
          code: value.saturdayDelivery.optionCode,
        });
      return serviceOptions;
    },
  },
  isEEIChecked: "IsEEIChecked",
  customsDeclarations: {
    key: "CustomDetails",
    transform: function (value: CustomsDeclarationsType) {
      if (value) {
        let custDetails = {
          ReasonForExport: value.packageContents,
          ContentDescription: value.packageContentsDescription,
          currency: value.currency,
          EEIFilingOption: value.EEIFilingOption
            ? {
                Type: value.EEIFilingOption.type,
                POAType: value.EEIFilingOption.POAType,
                ShipperFiled: value.EEIFilingOption.shipperFiled
                  ? {
                      Type: value.EEIFilingOption.shipperFiled.type,
                      ITNNumber: value.EEIFilingOption.shipperFiled.ITNNumber,
                      ReferenceNumber:
                        value.EEIFilingOption.shipperFiled.referenceNumber,
                    }
                  : undefined,
              }
            : undefined,
          ShipFromTaxInfo: value.shipFromTaxInfo
            ? {
                TaxIndentificationNumber:
                  value.shipFromTaxInfo.taxIndentificationNumber,
                TaxIdType: value.shipFromTaxInfo.taxIdType,
              }
            : undefined,
          UltimateConsigneeType: value.ultimateConsigneeType,
          PartiesToTransaction: value.partiesToTransaction,
          modeoftransport: value.modeOfTransport,
          Inbondcode: value.inBondCode,
          EntryNumber: value.entryNumber,
          PointOfOriginInfo: value.pointOfOriginInfo
            ? {
                PointOfOriginType: value.pointOfOriginInfo.pointOfOriginType,
                PointOfOriginValue: value.pointOfOriginInfo.pointOfOriginValue,
              }
            : undefined,
          SKUList: {
            Item: value.customsDeclarationItems.map((item) => {
              return {
                id: item.id ? item.id : null,
                orderId: item.orderId ? item.orderId : null,
                Description: item.description,
                NumberOfUnits: item.qty,
                ValuePerUnit: parseFloat(item.value),
                weight:
                  item.weight && item.weight !== ""
                    ? parseFloat(item.weight)
                    : 0,
                unitOfMeasurement: {
                  code: item.measurementUnit.code,
                  description: "",
                },
                HarmonizationCode: item.harmonizationCode,
                CountryOfOrigin: item.origin,
                EEIFormInformation: item.EEIFormInformation
                  ? {
                      ScheduleB: {
                        ScheduleBNumber:
                          item.EEIFormInformation.scheduleB.scheduleBNumber,
                        Quantity: item.EEIFormInformation.scheduleB.quantity,
                        unitOfMeasurement: {
                          code: item.EEIFormInformation.scheduleB
                            .unitOfMeasurement.code,
                        },
                      },
                      ExportType: item.EEIFormInformation.exportType,
                      IsLicenseDetailsRequired:
                        item.EEIFormInformation.isLicenseDetailsRequired,
                      LicenseDetails: item.EEIFormInformation
                        .isLicenseDetailsRequired
                        ? {
                            LicenseCode:
                              item.EEIFormInformation.licenseDetails
                                .licenseCode,
                            ECCNNumber:
                              item.EEIFormInformation.licenseDetails.ECCNNumber,
                            LicenseNumber:
                              item.EEIFormInformation.licenseDetails
                                .licenseNumber,
                            LicenseLineValue:
                              item.EEIFormInformation.licenseDetails
                                .licenseLineValue,
                          }
                        : undefined,
                      ExportInformation: item.EEIFormInformation
                        .isLicenseDetailsRequired
                        ? item.EEIFormInformation.exportInformation
                        : undefined,
                    }
                  : undefined,
              };
            }),
          },
        };
        return custDetails;
      } else {
        return null;
      }
    },
  },
  customerReference: {
    key: "customerReference",
    transform: function (value: string) {
      if (value) {
        return value;
      } else {
        return null;
      }
    },
  },
  carrierNotifications: "carrierNotifications",
  isNotificationsNeeded: "isNotificationsNeeded",
  NumberOfPieces: "rateRequest.NumberOfPieces",
  USPSEndorsement: "USPSEndorsement",
};

let mapWithoutEEIChecked = {
  "shipFrom.name": "rateRequest.FromAddress.name",
  "shipFrom.companyName": "rateRequest.FromAddress.companyName",
  "shipFrom.streetAddress": "rateRequest.FromAddress.streetAddress",
  "shipFrom.streetAddress2": "rateRequest.FromAddress.streetAddress2",
  "shipFrom.city": "rateRequest.FromAddress.city",
  "shipFrom.country": "rateRequest.FromAddress.country",
  "shipFrom.countryCode": "rateRequest.FromAddress.countryCode",
  "shipFrom.state": "rateRequest.FromAddress.state",
  "shipFrom.stateCode": "rateRequest.FromAddress.stateCode",
  "shipFrom.postalCode": "rateRequest.FromAddress.postalCode",
  "shipFrom.isResidence": "rateRequest.FromAddress.isResidence",
  "shipFrom.email": {
    key: "rateRequest.FromAddress.email",
    transform: function (value: string) {
      if (value === "") {
        return null;
      } else {
        return value;
      }
    },
  },
  "shipFrom.phone": "rateRequest.FromAddress.phone",
  // "shipFromAddressId":"rateRequest.FromAddress.shipFromAddressId",
  "shipTo.name": "rateRequest.ToAddress.name",
  "shipTo.companyName": "rateRequest.ToAddress.companyName",
  "shipTo.streetAddress": "rateRequest.ToAddress.streetAddress",
  "shipTo.streetAddress2": "rateRequest.ToAddress.streetAddress2",
  "shipTo.city": "rateRequest.ToAddress.city",
  "shipTo.country": "rateRequest.ToAddress.country",
  "shipTo.countryCode": "rateRequest.ToAddress.countryCode",
  "shipTo.state": "rateRequest.ToAddress.state",
  "shipTo.stateCode": "rateRequest.ToAddress.stateCode",
  "shipTo.postalCode": "rateRequest.ToAddress.postalCode",
  "shipTo.isResidence": "rateRequest.ToAddress.isResidence",
  "shipTo.email": {
    key: "rateRequest.ToAddress.email",
    transform: function (value: string) {
      if (value === "") {
        return null;
      } else {
        return value;
      }
    },
  },
  "shipTo.phone": "rateRequest.ToAddress.phone",
  shippingItems: {
    key: "rateRequest.lineItems",
    transform: function (value: ShippingItem[]) {
      const lineItems = [];
      value.forEach((item) => {
        for (let i = 0; i < item.quantity; i++) {
          lineItems.push({
            length: item.length ? parseFloat(item.length) : 0,
            height: item.height ? parseFloat(item.height) : 0,
            width: item.width ? parseFloat(item.width) : 0,
            weight:
              item.weight && item.weight !== "" ? parseFloat(item.weight) : 0,
            declaredValue: {
              currency: "CAD",
              amount: item.insurance ? parseFloat(item.insurance) : 0,
            },
            PackagingType: item.package.code,
            InsuranceType: item.InsuranceType ? item.InsuranceType.code : null,
          });
        }
      });
      return lineItems;
    },
  },
  unitOfMeasurement: "rateRequest.unitOfMeasurement",
  "selectedRate.account.id": "shippingCarrierAccountId",
  "selectedRate.account.accountType": "accountType",
  "selectedRate.service.serviceCode": {
    key: "carrierServiceType",
    transform: function (value: any) {
      return value.replaceAll(".", "_");
    },
  },
  shipDate: "shipDate",
  labelOptions: {
    key: "LabelOptions",
    transform: (value) => {
      let emailTemp = value.Email === undefined ? undefined : value.Email;
      let labelOptionsTemp = {
        Email: emailTemp,
        ShipmentLabelSize: value.labelSize,
      };
      return labelOptionsTemp;
    },
  },
  // "labelSize": "ShipmentLabelSize",
  serviceOptions: {
    key: "rateRequest.serviceOptions",
    transform: function (value: {
      serviceOption: ServiceOption;
      prerequisiteOptions?: ServiceOption[];
      ifNonDeliveryOption?: ServiceOption;
      saturdayDelivery?: ServiceOption;
    }) {
      const serviceOptions: reqServiceOptionType[] = [];
      if (value.serviceOption.optionCode)
        serviceOptions.push({
          code: value.serviceOption.optionCode,
          qualifier: value.serviceOption.qualifier,
          postOfficeAddress: value.serviceOption.postOfficeAddress,
        });
      // if (value.insuranceOption.amount) serviceOptions.push({
      //     code: value.insuranceOption.optionCode,
      //     amount: parseFloat(value.insuranceOption.amount),
      // });
      // if (value.CODOption.amount) serviceOptions.push({
      //     code: value.CODOption.optionCode,
      //     amount: parseFloat(value.CODOption.amount),
      //     qualifier: value.CODOption.qualifier
      // });
      if (value.prerequisiteOptions)
        serviceOptions.push(
          ...value.prerequisiteOptions.map((option) => {
            return {
              code: option.optionCode,
            };
          })
        );
      if (value.ifNonDeliveryOption?.optionCode)
        serviceOptions.push({
          code: value.ifNonDeliveryOption.optionCode,
        });
      if (value.saturdayDelivery?.optionCode)
        serviceOptions.push({
          code: value.saturdayDelivery.optionCode,
        });
      return serviceOptions;
    },
  },
  isEEIChecked: "IsEEIChecked",
  customsDeclarations: {
    key: "CustomDetails",
    transform: function (value: CustomsDeclarationsType) {
      if (value) {
        let custDetails = {
          ReasonForExport: value.packageContents,
          currency: value.currency,
          SKUList: {
            Item: value.customsDeclarationItems.map((item) => {
              return {
                id: item.id ? item.id : null,
                orderId: item.orderId ? item.orderId : null,
                Description: item.description,
                NumberOfUnits: item.qty,
                ValuePerUnit: parseFloat(item.value),
                weight:
                  item.weight && item.weight !== ""
                    ? parseFloat(item.weight)
                    : 0,
                unitOfMeasurement: {
                  code: item.measurementUnit.code,
                },
              };
            }),
          },
        };
        return custDetails;
      } else {
        return null;
      }
    },
  },
  customerReference: {
    key: "customerReference",
    transform: function (value: string) {
      if (value) {
        return value;
      } else {
        return null;
      }
    },
  },
  carrierNotifications: "carrierNotifications",
  isNotificationsNeeded: "isNotificationsNeeded",
  NumberOfPieces: "rateRequest.NumberOfPieces",
  isPOBox: "rateRequest.IsPOBox",
  subServices: "SubServiceTypes",
  USPSEndorsement: "USPSEndorsement",
};

const mapLTL = {
  "shipFrom.name": "FreightRateRequest.FromAddress.name",
  "shipFrom.companyName": "FreightRateRequest.FromAddress.companyName",
  "shipFrom.streetAddress": "FreightRateRequest.FromAddress.streetAddress",
  "shipFrom.streetAddress2": "FreightRateRequest.FromAddress.streetAddress2",
  "shipFrom.city": "FreightRateRequest.FromAddress.city",
  "shipFrom.country": "FreightRateRequest.FromAddress.country",
  "shipFrom.countryCode": "FreightRateRequest.FromAddress.countryCode",
  "shipFrom.state": "FreightRateRequest.FromAddress.state",
  "shipFrom.stateCode": "FreightRateRequest.FromAddress.stateCode",
  "shipFrom.postalCode": "FreightRateRequest.FromAddress.postalCode",
  "shipFrom.isResidence": "FreightRateRequest.FromAddress.isResidence",
  "shipFrom.email": {
    key: "FreightRateRequest.FromAddress.email",
    transform: function (value: string) {
      if (value === "") {
        return null;
      } else {
        return value;
      }
    },
  },
  "shipFrom.phone": "FreightRateRequest.FromAddress.phone",
  "shipTo.name": "FreightRateRequest.ToAddress.name",
  "shipTo.companyName": "FreightRateRequest.ToAddress.companyName",
  "shipTo.streetAddress": "FreightRateRequest.ToAddress.streetAddress",
  "shipTo.streetAddress2": "FreightRateRequest.ToAddress.streetAddress2",
  "shipTo.city": "FreightRateRequest.ToAddress.city",
  "shipTo.country": "FreightRateRequest.ToAddress.country",
  "shipTo.countryCode": "FreightRateRequest.ToAddress.countryCode",
  "shipTo.state": "FreightRateRequest.ToAddress.state",
  "shipTo.stateCode": "FreightRateRequest.ToAddress.stateCode",
  "shipTo.postalCode": "FreightRateRequest.ToAddress.postalCode",
  "shipTo.isResidence": "FreightRateRequest.ToAddress.isResidence",
  "shipTo.email": {
    key: "FreightRateRequest.ToAddress.email",
    transform: function (value: string) {
      if (value === "") {
        return null;
      } else {
        return value;
      }
    },
  },
  "shipTo.phone": "FreightRateRequest.ToAddress.phone",
  "paymentInformation.payerAddress": {
    key: "FreightRateRequest.paymentInformation.payerAddress",
    transform: function (value: Address) {
      let payerAddress = value;
      if (!payerAddress) {
        return null;
      }
      if (payerAddress.email === "") {
        payerAddress.email = null;
      }
      return payerAddress;
    },
  },
  "paymentInformation.shippingBillingOption":
    "FreightRateRequest.paymentInformation.shippingBillingOption",
  "handlingUnitOne.quantity": "FreightRateRequest.handlingUnitOne.quantity",
  "handlingUnitOne.type": "FreightRateRequest.handlingUnitOne.type",
  "handlingUnitTwo.quantity": "FreightRateRequest.handlingUnitTwo.quantity",
  "handlingUnitTwo.type": "FreightRateRequest.handlingUnitTwo.type",
  shippingItems: {
    key: "FreightRateRequest.lineItems",
    transform: function (value: ShippingItem[]) {
      const lineItems = [];
      value.forEach((item) => {
        lineItems.push({
          length: item.length ? parseFloat(item.length) : 0,
          height: item.height ? parseFloat(item.height) : 0,
          width: item.width ? parseFloat(item.width) : 0,
          weight: parseFloat(item.weight) * item.quantity,
          numberOfPieces: item.quantity,
          isDangerousGoodsIncluded: item.isDangerous,
          description: item.description,
          FreightClass: item.freightClass,
          packagingTypeCode: item.packagingTypeLTL.code,
          InsuranceType: item.InsuranceType ? item.InsuranceType.code : null,
        });
      });
      return lineItems;
    },
  },
  unitOfMeasurement: "FreightRateRequest.unitOfMeasurement",
  "selectedRate.account.id": "shippingCarrierAccountId",
  "selectedRate.account.accountType": "accountType",
  "selectedRate.service.serviceCode": "carrierServiceType",
  shipDate: "shipDate",
  IsFreightRequest: "IsFreightRequest",
  DocumentImageType: "DocumentImageType",
  instructions: {
    key: "instructions",
    transform: (value) => {
      if (
        !value?.pickupInstructions &&
        !value?.handlingInstructions &&
        !value?.deliveryInstructions
      ) {
        return null;
      }
      return value;
    },
  },
  labelOptions: {
    key: "LabelOptions",
    transform: (value) => {
      let emailTemp = value.Email === undefined ? undefined : value.Email;
      let labelOptionsTemp = {
        Email: emailTemp,
        ShipmentLabelSize: value.labelSize,
      };
      return labelOptionsTemp;
    },
  },
  // "labelSize": "ShipmentLabelSize",
  serviceOptions: {
    key: "FreightRateRequest.serviceOptions",
    transform: function (value: { LTLOptions?: ServiceOption[] }) {
      const serviceOptions: reqServiceOptionType[] = [];
      // LTL options
      if (value?.LTLOptions?.length > 0) {
        value.LTLOptions.forEach((option) =>
          serviceOptions.push({
            code: option.optionCode,
            qualifier: option.qualifier,
          })
        );
      }
      return serviceOptions;
    },
  },
  customerReference: {
    key: "customerReference",
    transform: function (value: string) {
      if (value) {
        return value;
      } else {
        return null;
      }
    },
  },
};
